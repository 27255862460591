import { Inject, Injectable, NgZone, ErrorHandler, Optional } from '@angular/core';
import { AgentConfigOptions, Span } from '@elastic/apm-rum';
import { testStorage } from '@aw/video-util';
import { InjectionToken, NgModule } from '@angular/core';
import {
  RouterModule,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { apm, ApmBase } from '@elastic/apm-rum';
import { detect } from 'detect-browser';

export const APM = new InjectionToken<ApmBase>('APM Base Client');

function keys(store) {
  const numKeys = store.length;
  const ks = [];
  for (let i = 0; i < numKeys; i++) {
    ks.push(store.key(i));
  }
  return ks;
}

@Injectable()
export class ApmErrorHandler extends ErrorHandler {
  constructor(@Inject(APM) public apmBase: ApmBase) {
    super();
  }

  handleError(error) {
    this.apmBase.captureError(error.originalError || error);
    super.handleError(error);
  }
}

function safeParse(stuff) {
  try {
    return JSON.parse(stuff);
  } catch (e) {
    return {};
  }
}

function slurp(list, object) {
  return list.reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});
}

@Injectable({
  providedIn: 'root',
})
export class ApmService {
  public environment: string;

  constructor(
    @Inject(APM) public apmBase: ApmBase,
    private readonly ngZone: NgZone,
    @Optional() public router: Router,
  ) {
    const transactions = new Map();

    this.router?.events.subscribe((event) => {
      // Trigger a page load transaction on navigation change
      if (event instanceof NavigationStart) {
        const transaction = this.apmBase.startTransaction(event.url.split('?')[0], 'page-load', {
          managed: false,
          canReuse: true,
        });
        transactions.set(event.url.split('?')[0], transaction);
        // end the transaction as failed on navigation error
      } else if (event instanceof NavigationError && transactions.get(event.url.split('?')[0])) {
        const transaction = transactions.get(event.url.split('?')[0]);
        this.apmBase.captureError(event.toString());
        setTimeout(() => transaction?.end(), 1000);
        transaction.outcome = 'failure';
        transactions.delete(event.url.split('?')[0]);
        // end the transaction on navigation cancel/end
      } else if (
        (event instanceof NavigationEnd || event instanceof NavigationCancel) &&
        transactions.get(event.url.split('?')[0])
      ) {
        const transaction = transactions.get(event.url.split('?')[0]);
        setTimeout(() => transaction?.end(), 1000);
        transactions.delete(event.url.split('?')[0]);
      }
    });
  }

  init(config: AgentConfigOptions, isEmbeddedApp?: boolean): ApmBase {
    const apmInstance = this.ngZone.runOutsideAngular(() => {
      this.environment = config.environment;
      return this.apmBase.init(config);
    });

    const detected = detect();
    let labels: any = {
      detected_os: detected.os,
      detected_name: detected.name,
      detected_version: detected.version,
      detected_type: detected.type,
    };
    apmInstance.addLabels(labels);

    function addMetadata(transaction): void {
      const maybeTokenPayload = sessionStorage.getItem('aw-token-payload') || '{}';
      const parsedTokenPayload = safeParse(maybeTokenPayload);
      const awSessionId = sessionStorage.getItem('aw-session-id');
      const localStorageKeys = keys(localStorage);
      const sessionStorageKeys = keys(sessionStorage);

      if (awSessionId) {
        labels = {
          ...labels,
          aw_session_id: awSessionId,
        };
        transaction.addLabels({ aw_session_id: awSessionId });
        /** make sure this is also appended to errors, yo */
        apmInstance.addLabels({ aw_session_id: awSessionId });
      }

      if (isEmbeddedApp) {
        labels = {
          ...labels,
          is_embedded_app: isEmbeddedApp,
        };
        transaction.addLabels({ is_embedded_app: isEmbeddedApp });
      }

      const stuffICareAbout = slurp(
        ['tenantKey', 'roomSourceId', 'encounterId', 'role', 'ehrId', 'ehrType', 'launchId'],
        parsedTokenPayload,
      );

      const { encounterId, ...otherStuff } = stuffICareAbout;

      labels = {
        ...labels,
        ...otherStuff,
        session_storage_keys: sessionStorageKeys,
        local_storage_keys: localStorageKeys,
      };
      transaction.addLabels(labels);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      for (const [_, span] of Object.entries(transaction._activeSpans)) {
        (span as Span).addLabels(labels);
      }
      apmInstance.setCustomContext({
        encounter_id: encounterId,
        ...otherStuff,
      });
      apmInstance.setCustomContext({ storage_enabled: testStorage() });
    }

    /** it is possible that transactions don't end well, so do this at start to be safe */
    this.apmBase.observe('transaction:start', addMetadata);
    /** it is possible not all metadata is available when a transaction starts, so do it at the end to be EXTRA SUPER SAFE */
    this.apmBase.observe('transaction:end', addMetadata);

    if (!apmInstance.isActive()) {
      return apmInstance;
    }

    /**
     * Start listening to route change once we
     * intiailize to set the correct transaction names
     */
    // this.observe()
    return apmInstance;
  }

  observe(): void {}
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: APM, useValue: apm }],
})
export class ApmModule {}
