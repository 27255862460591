import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import merge from 'lodash.merge';
import { AppConfig } from '@app/shared/model/app-config.model';
import { getLocalStorageItem } from '@app/utilities/local-storage-utils';
export const CarepointListServiceUrlToken = new InjectionToken('carepoint_list_service_url_token');
export const EnvUrlNameToken = new InjectionToken('env_url_name_token');

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private settings: AppConfig;
  private settingsCache: Promise<AppConfig>;
  private readonly settingsUrl = 'assets/config/app-config.json';

  constructor(private http: HttpClient) {}

  init(): Promise<AppConfig> {
    if (this.settingsCache) {
      return this.settingsCache;
    }
    this.settingsCache = this.http
      .get<AppConfig>(this.settingsUrl)
      .toPromise()
      .then((data) => (this.settings = this.getMergedSettingsWithLocalStorageData(data)));
    return this.settingsCache;
  }
  getSettings(): AppConfig {
    return this.settings;
  }

  private getMergedSettingsWithLocalStorageData(settings: AppConfig): AppConfig {
    const localSettings = getLocalStorageItem('aw-local-aehr-client');
    if (localSettings) {
      merge(settings, localSettings);
    }
    return settings;
  }
}
