import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Params } from '@angular/router';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'url-search-params-polyfill';
import { Logger } from 'loglevel';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';
import { ApmService } from '@app/apm-module';

export interface AwBearerToken {
  token: string | null;
}

const NULL_TOKEN = { token: null };
const authCodeKey = 'auth_code';
const authUriKey = 'auth_uri';
const tokenKey = 'token';

@Injectable({
  providedIn: 'root',
})
export class TokenResolverService {
  tokens = {};
  authCode: string;
  constructor(
    private httpClient: HttpClient,
    @Inject(LOGGER_TOKEN) public log: Logger,
    public apm: ApmService,
  ) {}

  getToken(queryParams: Params): Observable<Record<string, any>> {
    const authUri = queryParams[authUriKey];
    const existingToken = queryParams[tokenKey];
    this.authCode = queryParams[authCodeKey];

    if (authUri?.length && this.authCode?.length && !existingToken?.length) {
      const cachedToken = this.tokens[this.authCode];
      if (cachedToken?.length) {
        console.log('Using cached token value');
        return of({ token: cachedToken });
      }

      const tokenUrl = `${decodeURIComponent(authUri)}/${this.authCode}`;
      return this.httpClient.get(tokenUrl).pipe(
        map((data: Record<string, any>) => (data?.token?.length ? data : NULL_TOKEN)),
        tap((data: Record<string, any>) => {
          if (data?.token?.length) {
            console.log(`token retrieved successfully using the auth_code`);
            this.tokens[this.authCode] = data.token;
            sessionStorage.setItem('aw-bearer-token', data.token);
          } else {
            console.error(`token could not be retrieved for the given auth_code`);
            this.apm.apmBase.captureError('token could not be retrieved for the given auth_code');
          }
          this.setSessionStoragePatientData(data);
        }),
        catchError(this.handleError.bind(this)),
      );
    }
    return of(NULL_TOKEN);
  }

  parseToken(token: string): Record<string, any> | null {
    try {
      return jwt_decode(token);
    } catch (err) {
      return null;
    }
  }

  private setSessionStoragePatientData(data): void {
    const setStorage = (itemName, item) => {
      console.log(`Preferred ${itemName} retrieved successfully using the auth_code`);
      sessionStorage.setItem(itemName, item);
    };
    if (data?.patientDisplayName?.length) {
      setStorage('patient-display-name', data.patientDisplayName);
    }
    if (data?.patientPhoneNumber?.length) {
      setStorage('patient-phone-number', data.patientPhoneNumber);
    }
  }

  private handleError(error: HttpErrorResponse): Observable<AwBearerToken> {
    if (error?.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.apm.apmBase.captureError(error);
      this.apm.apmBase.captureError(`An error occurred while trying to retrieve the token`);
      console.error(`An error occurred while trying to retrieve the token`);
      this.log.error(
        `An error occurred while trying to retrieve the token:`,
        error?.error?.message,
      );
      this.apm.apmBase.captureError(
        `An error occurred while trying to retrieve the token: ${error?.error?.message}`,
      );
    } else {
      this.apm.apmBase.captureError(error);
      this.apm.apmBase.captureError(
        `Api returned an error while trying to retrieve the token:` +
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`,
      );
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.log.error(
        `Api returned an error while trying to retrieve the token:` +
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`,
      );
    }
    return of(NULL_TOKEN);
  }
}
