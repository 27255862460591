import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationEnd,
  Event,
  NavigationEnd,
  NavigationError,
  Params,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { StorageKeys } from '@app/shared/constants/web-storage';
import { TokenContext } from '@app/shared/model/token-context.model';
import { AppInitService } from '@app/shared/service/app-init.service';
import { isElectron } from '@app/_helpers';
import { AwPageViewEvent, ClientEventsService } from '@aw/client-events/dist/client-events';
import { byId } from '@aw/video-util';
import log from 'loglevel';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { environment } from '../environments/environment';
import { CustomizationService } from './shared/service/customization.service';
import { DOCUMENT_TOKEN } from './shared/service/document-token';
import { EnvConfigService, EnvUrlNameToken } from './shared/service/env-config.service';
import { TokenResolverService } from './shared/service/token-resolver.service';
import { WINDOW_TOKEN } from './shared/service/window-token';
import { fadeInOutAnimation } from '@app/shared/animations';
import { EnvironmentName } from '@app/shared/interface/environment-name.type';
import { Logger } from 'loglevel';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';
import { RouteName } from '@app/shared/model/route-name.enum';
import { ErrorType, getErrorQueryParams } from '@app/shared/components/error-modal/error-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [fadeInOutAnimation()],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'aehr-client';
  topBannerVisible = true;
  maxSizeBox = false;
  earlyVisitBoxWidth = false;
  isConsumerInterstitialPage = false;
  userOnSchedulePage = false;
  helpUrl = '/support';
  authParams: Params;
  userOnVisitNowPage = false;
  footer = false;
  feedback = false;
  branding = false;
  shouldHideBox = false;
  fullScreen = false;
  currentYear = new Date().getFullYear();
  classNames = '';
  storageKeys = StorageKeys;
  brandStyleElement: any;
  layoutType?: 'standAlone' | 'responsive';
  showThemeSwitcher: boolean;
  globalProgressBarIsVisible$ = new BehaviorSubject<boolean>(false);
  private sessionId: string;
  private isDestroyed$ = new Subject();

  // eslint-disable-next-line no-invalid-this
  toggleTheme = () => this.customizationService.toggleTheme();
  // eslint-disable-next-line no-invalid-this
  $ = (path: string) => this.customizationService.select<any>(path);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public configuration: AppInitService,
    private titleService: Title,
    @Inject(DOCUMENT_TOKEN) public document: Document,
    @Inject(WINDOW_TOKEN) public window: Window,
    private tokenResolverService: TokenResolverService,
    private dialog: MatDialog,
    private envConfigService: EnvConfigService,
    private clientEventsService: ClientEventsService,
    private customizationService: CustomizationService,
    @Inject(EnvUrlNameToken) private envUrlName: EnvironmentName,
    @Inject(LOGGER_TOKEN) public logger: Logger,
  ) {
    this.setFavicon();
    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe((val: Event) => {
      this.showProgressBarWhenLazyLoadingModule(val);
      if (val instanceof NavigationEnd) {
        const url = val?.url?.toLowerCase() || '';
        this.topBannerVisible = url.indexOf('end-call') === -1;
        this.isConsumerInterstitialPage = url.indexOf('call/intake') !== -1;
        this.configuration.hideAwPanelOnMainPageSubject$.next(this.isConsumerInterstitialPage);
        this.userOnSchedulePage = url.indexOf('/schedule') !== -1;
        const urlAfterRedirects = val?.urlAfterRedirects?.toLowerCase() || '';
        this.userOnVisitNowPage =
          urlAfterRedirects.indexOf('/start') !== -1 && url.indexOf('scheduletoken') === -1;

        /* For page view events */
        const rawToken = sessionStorage.getItem('aw-bearer-token');
        const rootRoute = this.route.firstChild ? this.route.firstChild : this.route;
        const paramMaps = rootRoute.snapshot?.queryParams;
        const awpv = this.setPageViewEventObject(paramMaps, urlAfterRedirects);
        if (!rawToken) {
          const message = 'No token data loading the application.';
          console.warn(message);
          this.router.navigate([RouteName.Error], getErrorQueryParams(ErrorType.token, message));
          return;
        }
        this.clientEventsService.pageView(awpv, rawToken, true, true);

        const tokenContext: TokenContext = TokenContext.parse(rawToken);
        const isThankYou: boolean = url.indexOf('/thank-you') !== -1;
        // eslint-disable-next-line radar/no-collapsible-if
        if (isThankYou) {
          if (tokenContext?.isAmWellNow() && tokenContext?.isProvider() && !isElectron()) {
            this.footer = true;
          }
        }
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd && event.snapshot.children.length === 0),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((event: ActivationEnd) => {
        this.footer = event.snapshot.data.footer;
        this.feedback = event.snapshot.data.feedback;
        this.branding = event.snapshot.data.branding;
        this.fullScreen = event.snapshot.data.fullScreen;
        this.maxSizeBox = event.snapshot.data.maxSizeBox;
        this.earlyVisitBoxWidth = event.snapshot.data.earlyVisitBoxWidth;
        this.layoutType = event.snapshot.data.layoutType;
      });
    this.configuration.getAppClasses().subscribe((values) => (this.classNames = values.join(' ')));
  }

  ngOnInit() {
    this.handleFailedModuleLoading();
    this.configuration.hideAwPanelOnMainPageSubject$.subscribe((isHidden) => {
      this.shouldHideBox = isHidden;
      if (window.location.href.includes('call/intake')) {
        this.shouldHideBox = true;
      }
    });

    // eslint-disable-next-line radar/no-duplicate-string
    if (!sessionStorage.getItem('aw-session-id')) {
      this.sessionId = uuid();
      sessionStorage.setItem('aw-session-id', this.sessionId);
    }

    // eslint-disable-next-line rxjs/no-async-subscribe
    this.route.queryParams.pipe(takeUntil(this.isDestroyed$)).subscribe(async (params: Params) => {
      if (params.token) {
        sessionStorage.setItem('aw-bearer-token', params.token);
        const tokenContext: TokenContext = TokenContext.parse(params.token);
        if (tokenContext.isAmWellNow() && tokenContext.isProvider()) {
          this.helpUrl = `${this.configuration.amwellNowUrl}/help`;
        }
      }
      if (params.sessionId) {
        // use aw-session-id going forward.
        this.sessionId = params.sessionId;
        sessionStorage.setItem('aw-session-id', this.sessionId);
        sessionStorage.setItem('sessionId', this.sessionId);
      }

      this.helpUrl = this.getHelpUrlByParams(params);
    });

    this.setupServerSideLogging();

    // eslint-disable-next-line no-console
    console.info(
      `Git Branch: ${environment.branch}\n` +
        `Git Hash: ${environment.commit}\n` +
        `Version: ${environment.version}`,
    );

    this.showThemeSwitcher = this.envConfigService.getSettings()?.showThemeSwitcher || false;
  }

  handleFailedModuleLoading() {
    this.router.events
      .pipe(
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.isDestroyed$),
        filter((event: RouterEvent): event is NavigationError => event instanceof NavigationError),
      )
      .subscribe(() => {
        this.router.navigateByUrl(RouteName.Error);
      });
  }

  assignBoxWidth() {
    return {
      conditionalWidth: !this.maxSizeBox,
      earlyVisitBoxWidth: this.earlyVisitBoxWidth,
    };
  }

  public get footerClass(): string {
    return this.router.url.includes('/tech-check') ? 'join-visit-shift' : '';
  }

  /**
   * This method configures the sending of
   * client side logs to the logging endpoint.
   */
  public setupServerSideLogging(): void {
    const configLogLevel: number =
      log.levels[this.envConfigService.getSettings().logging.logLevel.toUpperCase()];
    if (this.isNullOrUndefined(configLogLevel)) {
      this.logger.error(
        `The logLevel value: '${this.envConfigService.getSettings().logging.logLevel}' is invalid.`,
      );
      this.logger.error('Client-side logs will not be sent to the logging endpoint.');
    } else {
      const token = sessionStorage.getItem(this.storageKeys.VMSToken);
      const parsedToken = this.tokenResolverService.parseToken(token);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const tenantKey = parsedToken ? parsedToken.tenantKey : 'Not available';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const participantSid =
        sessionStorage.getItem(this.storageKeys.ParticipantSid) || 'Not available';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const userAgent = navigator.userAgent.toLowerCase();

      if (this.envConfigService.getSettings().logging.enabled) {
        this.logger.enableAll();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  setParamsForEvents(paramMaps: any): object {
    if (paramMaps && (paramMaps?.sessionId || paramMaps.invitationID)) {
      const sessionId = paramMaps.sessionId || this.sessionId;
      const paramMap = {
        invitation_id: paramMaps.invitationID,
        client_session_id: sessionId,
      };
      return paramMap;
    }
    return {};
  }

  setPageViewEventObject(paramMaps: Params, urlAfterRedirects: string): AwPageViewEvent {
    const windowDimensions = window.innerWidth.toString() + ' x ' + window.innerHeight.toString();
    const sessionId =
      paramMaps?.sessionId || this.sessionId || sessionStorage.getItem('aw-session-id');
    const transactionId =
      paramMaps?.transactionId || sessionStorage.getItem('aw-transaction-id') || '';
    const participantSid = sessionStorage.getItem('participantSid') || '';
    const roomSid = sessionStorage.getItem('roomSid') || '';
    const urlQueryIndex = urlAfterRedirects.indexOf('?');
    // return new AwPageViewEvent();

    const awpv = new AwPageViewEvent();
    awpv.browser_size = windowDimensions;
    awpv.product_id = 'Converge for EHRs';
    awpv.service_version = environment.version;
    awpv.user_agent = window.navigator.userAgent;
    awpv.sessionId = sessionId;
    awpv.transactionId = transactionId;

    awpv.participant_sid = participantSid;
    awpv.room_sid = roomSid;
    awpv.event_message = {
      page_location:
        urlAfterRedirects.indexOf('?') > -1
          ? urlAfterRedirects.substring(0, urlQueryIndex)
          : urlAfterRedirects,
      page_path: new URL(window.location.href).origin + urlAfterRedirects,
      page_title: document.title, // probably a better way to retrieve this
    };
    const invitationId = paramMaps?.invitationID || paramMaps?.invitationId;
    if (this.isConsumerInterstitialPage && invitationId) {
      const paramMap = {
        invitation_id: invitationId,
      };
      awpv.event_message.param_map = paramMap;
    } else {
      awpv.event_message.param_map = this.setParamsForEvents(paramMaps);
    }
    return awpv;
  }

  setFavicon() {
    if (!!this.configuration.imageURLs?.favicon) {
      byId('appFavicon').setAttribute('href', this.configuration.imageURLs.favicon);
    }
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined || value === 'undefined' || value === 'null';
  }

  private showProgressBarWhenLazyLoadingModule(event: Event): void {
    if (event instanceof RouteConfigLoadStart) {
      this.globalProgressBarIsVisible$.next(true);
    } else if (event instanceof RouteConfigLoadEnd) {
      this.globalProgressBarIsVisible$.next(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  private getHelpUrlByParams(params: Params): string {
    const { amwellNowUrl } = this.configuration;
    let url = this.helpUrl;

    if (params.fromBrand) {
      url = `${amwellNowUrl}/help`;
    }

    if (params.auth_uri && params.auth_code) {
      // ensure we can retrieve token on help page which opens in new tab
      const authUri = encodeURIComponent(params.auth_uri);
      const authCode = encodeURIComponent(params.auth_code);
      if (authUri && authCode) {
        url += `?auth_uri=${authUri}&auth_code=${authCode}`;
      }
    }

    return url;
  }
}
